
* {
   font-size: 14px;
   line-height: 1;
   color:white;
}
/*

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #212529;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-content {
  background-color: #212529;
  min-height: 100vh;
  color: #212529;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left:auto
}


@media (min-width: 576px) {
    .App-content, .container-sm {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .App-content, .container-md, .container-sm {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .App-content, .container-lg, .container-md, .container-sm {
        max-width:960px
    }
}

@media (min-width: 1200px) {
    .App-content, .container-lg, .container-md, .container-sm, .container-xl {
        max-width:1140px
    }
}

@media (min-width: 1400px) {
    .App-content, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width:1320px
    }
}

tr.goal {
    border-bottom: .1px solid rgba(158, 158, 158, 1);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.svg-inline--fa{
  font-size:14pt;
}
.icon_fa{
	color: red;
}

*/

.week_head{
  font-size: min(max(6pt, 3vw), 22px);
}

.week_icon{
  font-size: min(max(12pt, 5vw), 22px); /* larger of X/Y, no more than 22px */
  
}

.icon{
	/*width: 18px;
	height: 18px;*/
	/*font-size: 1em !important;*/
    font-size: min(max(16px, 4vw), 22px);
    /*
    margin-left: 3px;
    margin-right: 3px;
    */
}
.icon_up{
	color: green;
}
.icon_down{
	color: red;
}
.icon_equal{
	color: gray;
}
.on{
	color: white;
}
.off{
	color: red;
	opacity: 0.3;
}
.null{
	color:gray;
	opacity: 0.3;
}

.goal_cell_monthyear {
    display: none;
}
.goal_cell_misses {
    display: none;
}
.bottom_nav{
    display: block;  
}
.measure_card{
  padding-left:10px;
  padding-right:10px;
  padding-bottom:10px;
  width: 160px;
  height: 210px;
}
@media screen and (min-width: 601px) {
    .goal_cell_monthyear {
        display: table-cell;
    }
    .goal_cell_misses {
        display: table-cell;
    }
    .bottom_nav{
      display: none;  
    }

}
